import React from 'react'

import Logo from './Logo'
//import Search from './Search' //AOC
import TopNav from './TopNav'

const Header = (props) => (
    <header className="header">
        <div className="navigation-trigger hidden-xl-up" data-ma-action="aside-open" data-ma-target=".sidebar" onClick={props.toggleMenuHandler}>
            <div className="navigation-trigger__inner">
                <i className="navigation-trigger__line"></i>
                <i className="navigation-trigger__line"></i>
                <i className="navigation-trigger__line"></i>
            </div>
        </div>
        <Logo />
     {/* AOC
        <Search />
     */}
        <TopNav />
    </header>
)

export default Header