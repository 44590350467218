import React, { Component } from 'react'
import moment from 'moment'

import { API } from '../../../config/api'

import ResponsiveTable from '../../template/tables/ResponsiveTable'
import Card from '../../template/Card'
import ContentTitle from '../../template/ContentTitle'
import Loading from '../../template/Loading'

class Statement extends Component {
    constructor(props) {
        super(props)

        this.state = {
            'rows': [],
            'loading': true
        }
    }

    componentDidMount = () => {
        document.title = 'Financeiro'
        this.loadSuppliers()
    }

    componentWillReceiveProps(nextProps){
        this.loadSuppliers()
    }

    loadSuppliers = async () => {
        await this.setState({ loading: true, rows: [] })
        API.get('statements')
            .then((resultJson) => {
                if (resultJson.status === 200)
                {
                    this.setState({
                        loading: false,
                        rows: resultJson.data.data
                    })
                }
            })
    }

    renderStatus = (status) => {
        if (status === 1)
        {
            return <span className="badge badge-warning">Pendente</span>
        }

        if (status === 2)
        {
            return <span className="badge badge-success">Processado</span>
        }

        if (status === 3)
        {
            return <span className="badge badge-primary">Estornado</span>
        }

        if (status === 4)
        {
            return <span className="badge badge-danger">Cancelado</span>
        }

        return <span className="badge badge-secondary">Erro no status</span>
    }

    renderRow = (statement) => {
        return (
            <tr key={statement.id}>
                <th scope="row">{statement.id}</th>
                <td>{statement.created_by ? statement.created_by.name : ''}</td>
                <td></td>
                <td></td>
                <td>{ statement.description }</td>
                <td>{ moment(statement.due_date).format('DD/MM/YYYY') }</td>
                <td>{ moment(statement.effective_date).format('DD/MM/YYYY') }</td>
                <td>R$ { statement.value_br }</td>
                <td>{ moment(statement.created_at).format('DD/MM/YYYY HH:mm:ss') }</td>
                <td>
                    {this.renderStatus(statement.status)}
                </td>
            </tr>
        )
    }

    render = () => {
        return (
            <React.Fragment>
                <ContentTitle title='Financeiro' />
                <Card
                    title='Extrato Geral'
                    subtitle=''
                >
                    {
                        this.state.loading ?
                        <Loading /> :
                        <ResponsiveTable
                            headers={['ID', 'Usuario', 'Conta', 'Plano de Contas', 'Descrição', 'Data de Pagamento', 'Data de Competência', 'Valor', 'Criado em', 'Status']}
                            rows={this.state.rows}
                            renderRow={this.renderRow}
                        />
                    }
                </Card>
            </React.Fragment>
        )
    }
}

export default Statement