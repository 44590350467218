import React, { Component } from 'react'

import Card from '../../template/Card'
import ContentTitle from '../../template/ContentTitle'
import Loading from '../../template/Loading'

import SupplierFormik from './SupplierFormik'

import { API } from '../../../config/api'

class SupplierForm extends Component {
    constructor(props) {
        super(props)

        this.state = {
            loadingSupplier: true,
            supplier: {}
        }
    }

    componentDidMount = () => {
        const { id } = this.props.match.params
        document.title = `${id === undefined ? 'Adicionar' : 'Editar'} Fornecedor`

        if (id === undefined)
        {
            this.setState({ loadingSupplier: false })
        }
        else
        {
            API.get(`suppliers/${id}`)
                .then((result) => {
                    console.log(result.data.data)
                    this.setState({
                        loadingSupplier: false,
                        supplier: result.data.data
                    })
                })
        }
    }

    render = () => {
        if (this.state.loadingSupplier === true)
        {
            return <Loading />
        }

        return (
            <React.Fragment>
                <ContentTitle title={this.state.supplier.id ? 'Editar Fornecedor' : 'Cadastrar Fornecedor'} />
                <Card
                    title='Informe os dados do fornecedor'
                    subtitle=''
                >
                    <SupplierFormik supplier={this.state.supplier} />
                </Card>
            </React.Fragment>
        )
    }
}

export default SupplierForm