import React, { Component } from 'react'
import axios from 'axios'
import jwt from 'jsonwebtoken'
import moment from 'moment'
import { HashRouter } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'

import 'react-toastify/dist/ReactToastify.css'

import Login from '../components/login/Login'
import PageLoader from '../components/template/PageLoader'
import Header from '../components/template/header/Header'
import Side from '../components/template/Side'
import Footer from '../components/template/Footer'

import Routes from './Routes'

import 'bootstrap/dist/css/bootstrap.min.css'

import '../assets/vendors/bower_components/material-design-iconic-font/dist/css/material-design-iconic-font.min.css'
import '../assets/css/app.css'

import './App.css'

class App extends Component {
    constructor(props) {
        super(props)

        this.state = {
            menuToggled: false,
            jwtToken: ''
        }
    }

    check = () => {
        const jwtToken = localStorage.getItem('jwtToken')
        if (jwtToken)
        {
            const tokenExpiration = jwt.decode(jwtToken).exp
            const tokenExpirationTimeInSeconds = (tokenExpiration - moment(Math.floor(Date.now() / 1000)))
            console.log(tokenExpiration, tokenExpirationTimeInSeconds)
            if (tokenExpiration && tokenExpirationTimeInSeconds < 20)
            {
                this.setState({ jwtToken: '' })
            }
            else if (!this.state.jwtToken)
            {
                this.setState({ jwtToken })
                axios.defaults.headers.common['Authorization'] = `Bearer ${jwtToken}`
            }
        }
        else if (this.state.jwtToken)
        {
            this.setState({ jwtToken: '' })
        }
    }

    componentDidMount = () => {
        this.check()
    }

    componentDidUpdate = () => {
        this.check()
    }

    loginDone = () => {
        this.check()
    }

    toggleMenuHandler = () => {
        this.setState({ menuToggled: !this.state.menuToggled })
    }

    handleLogout = () => {
        localStorage.removeItem('jwtToken')
        this.setState({ jwtToken: '' })
        axios.defaults.headers.common['Authorization'] = ''
    }

    render() {
        if (!this.state.jwtToken)
        {
            return <Login loginDone={() => this.loginDone()} />
        }

        return (
            <HashRouter>
                <React.Fragment>
                    <PageLoader />
                    <ToastContainer autoClose={6000} position='bottom-right' />
                    <Header toggleMenuHandler={this.toggleMenuHandler} />
                    <Side
                        toggled={this.state.menuToggled}
                        toggleMenuHandler={this.toggleMenuHandler}
                        handleLogout={this.handleLogout}
                    />
                    <section className='content'>
                        <Routes />
                        <Footer />
                    </section>
                </React.Fragment>
            </HashRouter>
        )
    }
}

export default App
