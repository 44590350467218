import React, { Component } from 'react'

class Submenu extends Component {
    constructor(props) {
        super(props)

        this.state = {
            toggled: false
        }
    }

    handleToggleMenu = () => {
        this.setState({ toggled: !this.state.toggled })
    }

    render() {
        return (
            <li className={`navigation__sub${this.state.toggled ? ' navigation__sub--toggled' : ''}`}>
                <a href='javascript:;' onClick={this.handleToggleMenu}><i className={`zmdi zmdi-${this.props.icon}`}></i> {this.props.label}</a>
                <ul style={{ display: this.state.toggled ? 'block' : '' }}>
                    {this.props.children}
                </ul>
            </li>
        )
    }
}

export default Submenu