import React from 'react'

export default props => (
    <footer className="footer hidden-xs-down">
       {/* AOC
       
       <p>Todos os direitos reservados.</p>

        
        <ul className="nav footer__nav">
            <a className="nav-link" href="/">Site</a>
            <a className="nav-link" href="/">Buscar passagens</a>
            <a className="nav-link" href="/">A empresa</a>
            <a className="nav-link" href="/">Contato</a>
        </ul>
        */}
    </footer>
)