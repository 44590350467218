import React from 'react'

const Card = (props) => (
    <div className="card">
        <div className="card-body">
            {props.title ? <h4 className="card-title">{props.title}</h4> : null}
            {props.subtitle ? <h6 className="card-subtitle">{props.subtitle}</h6> : null}
            {props.children}
        </div>
    </div>
)

export default Card