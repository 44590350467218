import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import { API } from '../../../config/api'

import ResponsiveTable from '../../template/tables/ResponsiveTable'
import ActionButtons from '../../template/buttons/actionButtons/ActionButtons'
import Card from '../../template/Card'
import ContentTitle from '../../template/ContentTitle'
import Loading from '../../template/Loading'

class Supplier extends Component {
    constructor(props) {
        super(props)

        this.state = {
            'rows': [],
            'loading': true
        }
    }

    componentDidMount = () => {
        document.title = 'Fornecedores'
        this.loadSuppliers()
    }

    componentWillReceiveProps(nextProps){
        this.loadSuppliers()
    }

    loadSuppliers = async () => {
        await this.setState({ loading: true, rows: [] })
        API.get('suppliers')
            .then((resultJson) => {
                if (resultJson.status === 200)
                {
                    this.setState({
                        loading: false,
                        rows: resultJson.data.data
                    })
                }
            })
    }

    renderRow = (supplier) => {
        return (
            <tr key={supplier.id}>
                <th scope="row">{supplier.id}</th>
                <td>{supplier.name}</td>
                <td>{supplier.cpf}</td>
                <td>{supplier.email}</td>
                <td>{supplier.phone}</td>
                <td>{supplier.telephone}</td>
                <td><ActionButtons baseUrl='/supply/suppliers' id={supplier.id} update /></td>
            </tr>
        )
    }

    renderTable = () => {
        if (this.state.loading === true)
        {
            return <Loading />
        }

        return (
            <ResponsiveTable
                headers={['ID', 'Nome', 'CPF', 'E-mail', 'Celular', 'Telefone', 'Editar']}
                rows={this.state.rows}
                renderRow={this.renderRow}
            />
        )
    }

    render = () => {
        return (
            <React.Fragment>
                <ContentTitle title='Estoque' />
                <Card
                    title='Fornecedores'
                >
                    <div className='pb-4'>
                        <Link to='/supply/suppliers/create'>
                            <button className='btn btn-primary'>Cadastrar Novo Fornecedor</button>
                        </Link>
                    </div>
                    {this.renderTable()}
                </Card>
            </React.Fragment>
        )
    }
}

export default Supplier