import React from 'react'

import contentLoading from '../../assets/img/airplane-loader-mini.gif'

const Loading = () => (
    <div className='text-center'>
        <img className='img-fluid' alt='loading...' src={contentLoading} />
    </div>
)

export default Loading