import React from 'react'
import { Link } from 'react-router-dom'

import IconButton from '../IconButton'

const ActionButtons = (props) => (
    <React.Fragment>
        {props.read
            ? <IconButton color='secondary' icon='search' />
            : null
        }
        {props.update
            ? <Link to={`${props.baseUrl}/update/${props.id}`}><IconButton color='primary' icon='edit' /> </Link>
            : null
        }
        {props.delete ? <IconButton color='danger' icon='delete' /> : null}
    </React.Fragment>
)

export default ActionButtons