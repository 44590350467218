import React, { Component } from 'react'

import { API } from '../../../config/api'

import ResponsiveTable from '../../template/tables/ResponsiveTable'
import Card from '../../template/Card'
import ContentTitle from '../../template/ContentTitle'
import Loading from '../../template/Loading'

class Airline extends Component {
    constructor(props) {
        super(props)

        this.state = {
            'rows': [],
            'loading': true
        }
    }

    componentDidMount = () => {
        document.title = 'Companhias'
        this.loadAirlines()
    }

    componentWillReceiveProps(nextProps){
        this.loadAirlines()
    }

    loadAirlines = async () => {
        await this.setState({ loading: true, rows: [] })
        API.get('airlines')
            .then((resultJson) => {
                if (resultJson.status === 200)
                {
                    this.setState({
                        loading: false,
                        rows: resultJson.data.data
                    })
                }
            })
    }

    renderRow = (airline) => {
        return (
            <tr key={airline.id}>
                <th scope="row">{airline.id}</th>
                <td>{airline.name}</td>
                <td>{airline.markup_br} %</td>
                <td>{airline.minimum_stock_per_supplier_br || 0}</td>
                {/* <td><ActionButtons baseUrl='/supply/airlines' id={airline.id} update /></td> */}
            </tr>
        )
    }

    renderTable = () => {
        if (this.state.loading === true)
        {
            return <Loading />
        }

        return (
            <ResponsiveTable
                headers={['ID', 'Nome', 'Markup %', 'Estoque mínimo por fornecedor'/*, 'Ações'*/]}
                rows={this.state.rows}
                renderRow={this.renderRow}
            />
        )
    }

    render = () => {
        return (
            <React.Fragment>
                <ContentTitle title='Estoque' />
                <Card
                    title='Companhias'
                >
                    {this.renderTable()}
                </Card>
            </React.Fragment>
        )
    }
}

export default Airline