import axios from 'axios'

const API = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
})

API.interceptors.response.use(null, (error) => {
    if (error && error.rersponse && error.response.status === 401)
    {
        localStorage.removeItem('jwtToken')
    }

    return Promise.reject(error)
})

export { API }
export default API