import React, { Component } from 'react'

import Card from '../../template/Card'
import ContentTitle from '../../template/ContentTitle'

import AdjustBalanceFormik from './AdjustBalanceFormik'

class AdjustBalance extends Component {
    componentDidMount = () => {
        document.title = 'Ajustar saldo'
    }

    render = () => {
        return (
            <React.Fragment>
                <ContentTitle title='Estoque' />
                <Card
                    title='Ajustar Estoque'
                    subtitle='O ajuste de estoque deve ser utilizado para corrigir um lançamento prévio incorreto'
                >
                    <AdjustBalanceFormik />
                </Card>
            </React.Fragment>
        )
    }
}

export default AdjustBalance