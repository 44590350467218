import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import { API } from '../../../config/api'

import ResponsiveTable from '../../template/tables/ResponsiveTable'
import Card from '../../template/Card'
import ContentTitle from '../../template/ContentTitle'
import Loading from '../../template/Loading'

class LowBalance extends Component {
    constructor(props) {
        super(props)

        this.state = {
            'rows': [],
            'loading': true
        }
    }

    componentDidMount = () => {
        document.title = 'Estoque baixo'
        this.loadReport()
    }

    componentWillReceiveProps(nextProps){
        this.loadReport()
    }

    loadReport = async () => {
        await this.setState({ loading: true, rows: [] })
        API.patch('unlock-expired-suppliers')
            .then(() => API.get('reports-airline-suppliers-low-balance'))
            .then((resultJson) => {
                if (resultJson.status === 200)
                {
                    this.setState({
                        loading: false,
                        rows: resultJson.data.data.map((row) => {
                            row.showBlockReasons = false
                            return row
                        })
                    })
                }
            })
    }

    renderStatus = (reportRow) => {
        if (reportRow.blocked === 1)
        {
            return <span className="badge badge-danger" href="#popover">Bloqueado</span>
        }

        if (reportRow.in_use)
        {
            return <span className="badge badge-warning">Em uso OP {reportRow.in_use}</span>
        }

        return <span className="badge badge-success">Disponível</span>
    }

    renderRow = (reportRow, index) => {
        return (
            <tr key={reportRow.id}>
                <th scope="row">{reportRow.id}</th>
                <td>{reportRow.supplier ? reportRow.supplier.name : ''}</td>
                <td>{reportRow.supplier ? reportRow.supplier.cpf : ''}</td>
                <td>{reportRow.miles_br}</td>
                <td>{reportRow.airline ? reportRow.airline.name : ''}</td>
                <td>{reportRow.minimum_ticket_issue_br || 0}</td>
                <td>R$ {reportRow.price_br}</td>
                <td className="tdActions">
                    {this.renderStatus(reportRow)}
                </td>
            </tr>
        )
    }

    render = () => {
        return (
            <React.Fragment>
                <ContentTitle title='Estoque' />
                <Card
                    title='Estoque completo'
                    subtitle=''
                >
                    {
                        this.state.loading ?
                        <Loading /> :
                        <ResponsiveTable
                            headers={['ID', 'Nome', 'CPF', 'Milhas', 'Companhia', 'Qtde min. emissão', 'Valor de Compra', 'Status atual']}
                            rows={this.state.rows}
                            renderRow={this.renderRow}
                        />
                    }
                </Card>
            </React.Fragment>
        )
    }
}

export default LowBalance