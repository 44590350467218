import React, { Component } from 'react'

import { API } from '../../../config/api'

import ResponsiveTable from '../../template/tables/ResponsiveTable'
import Card from '../../template/Card'
import ContentTitle from '../../template/ContentTitle'
import Loading from '../../template/Loading'

class Summary extends Component {
    constructor(props) {
        super(props)

        this.state = {
            'rows': [],
            'loading': true
        }
    }

    componentDidMount = () => {
        document.title = 'Sumário'
        this.loadSummary()
    }

    componentWillReceiveProps(nextProps){
        this.loadSummary()
    }

    loadSummary = async () => {
        await this.setState({ loading: true, rows: [] })
        API.get('airline-suppliers-summary')
            .then((resultJson) => {
                if (resultJson.status === 200)
                {
                    this.setState({
                        loading: false,
                        rows: resultJson.data.data
                    })
                }
            })
    }

    renderRow = (summary, index) => {
        summary.average_price = parseFloat(summary.average_price).toFixed(3)
        const base_percent = (parseFloat(summary.airline_markup) + 100) / 100
        const base_value = (summary.average_price * base_percent).toFixed(3)
        // const percents = [
        //     0, 2.173, 4.347, 5.661, 6.956
        // ]
        // const values = []
        // percents.forEach((percent) => {
        //     percent = (100 - percent) / 100
        //     values.push((base_value * percent).toFixed(3))
        // })

        return (
            <tr key={index}>
                <th scope="row">{summary.airline_name}</th>
                <td>{summary.active_miles.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}</td>
                <td>{summary.blocked_miles.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}</td>
                <td>{summary.inactive_miles.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}</td>
                <td>R$ {summary.average_price}</td>
                <td>{summary.airline_markup}%</td>
                <td>R$ {base_value}</td>
                {
                // values.map((value, idx) => (
                //    <td key={idx}>{value}</td>
                // ))
                }
            </tr>
        )
    }

    render = () => {
        return (
            <React.Fragment>
                <ContentTitle title='Estoque' />
                <Card
                    title='Sumário'
                    subtitle='Visão geral de custo e valores de venda por companhia'
                >
                    {
                        this.state.loading ?
                        <Loading /> :
                        <ResponsiveTable
                            headers={[
                                'Companhia',
                                'Estoque Ativo',
                                'Estoque Bloqueado',
                                'Estoque Inativo',
                                'Custo médio',
                                'Acréscimo',
                                'Preço de Venda',
                            ]}
                            rows={this.state.rows}
                            renderRow={this.renderRow}
                        />
                    }
                </Card>
            </React.Fragment>
        )
    }
}

export default Summary