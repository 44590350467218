import React, { Component } from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import isEmpty from 'lodash/isEmpty'
import CurrencyInput from 'react-currency-input'
import { Redirect } from 'react-router'
import Select from 'react-select'
import { toast } from 'react-toastify'
import * as Yup from 'yup'

import TransactionType from '../../../enums/TransactionType'
import MovementType from '../../../enums/MovementType'

import Loading from '../../template/Loading'

import { API } from '../../../config/api'

const AirlineSupplierSchema = Yup.object().shape({
    airline: Yup.string()
        .required('Selecione a Companhia Aérea'),
    amount: Yup.string()
        .required('Informe a quantidade de milhas compradas'),
    days_to_pay: Yup.number()
        .required('Informe a quantidade de dias para o pagamento')
        .min(0, 'Informe um valor maior ou igual a zero'),
    price: Yup.string()
        .required('Informe o valor'),
    expiration_date: Yup.string()
        .required('Informe uma data'),
    // minimum_ticket_issue: Yup.string(),
    number_cpfs: Yup.number()
        .required('Informe a quantidade de CPFs')
        .min(1, 'Informe um número maior ou igual a 1'),
    payment_instructions: Yup.string()
        .required('Informe os dados para pagamento'),
    login: Yup.string()
        .required('Informe o login'),
    password: Yup.string()
        .required('Informe a senha'),
    redemption_password: Yup.string(),
})

class AirlineSupplierFormik extends Component {
    constructor(props) {
        super(props)

        this.state = {
            airlines: [],
            suppliers: [],
            suppliersOptions: [],

            formValues: {
                supplier: ''
            },

            redirectTo: null
        }
    }

    componentDidMount = () => {
        this.loadAirlines()
        this.loadSuppliers()
    }

    loadAirlines = () => {
        API.get('airlines')
            .then((response) => {
                if (response.status === 200)
                {
                    this.setState({ airlines: response.data.data })
                }
            })
    }

    loadSuppliers = () => {
        API.get('suppliers')
            .then((response) => {
                if (response.status === 200)
                {
                    return this.setState({ suppliers: response.data.data })
                }
            })
            .then((response) => {
                const suppliersOptions = this.state.suppliers && this.state.suppliers.map((row) => {
                    return {value: row.id, label: row.name}
                })
                this.setState({ suppliersOptions })
            })
    }

    changeTotal = (amount, price) => {
        price = parseFloat(price.replace(/R\$\ /g, '').replace(/\./g, '').replace(/\,/g, '.'))
        amount = parseFloat(amount.replace(/\./g, '').replace(/\,/g, '.'))
        const total = (price * (amount / 1000)).toFixed(2)
        this.props.changeTotal(total)
    }

    loadPaymentInfo = (airline, supplier, setFieldValue) => {
        setFieldValue('login', '')
        setFieldValue('password', '')
        setFieldValue('redemption_password', '')
        setFieldValue('payment_instructions', '')

        if (!airline || !supplier)
        {
            return
        }

        API.get(`airlines/${airline}/suppliers/${supplier}`)
            .then((response) => {
                if (response.status === 200)
                {
                    const row = response.data.data
                    if (row.login)
                    {
                        setFieldValue('login', row.login)
                    }
                    if (row.password)
                    {
                        setFieldValue('password', row.password)
                    }
                    if (row.redemption_password)
                    {
                        setFieldValue('redemption_password', row.redemption_password)
                    }
                    if (row.payment_instructions)
                    {
                        setFieldValue('payment_instructions', row.payment_instructions)
                    }
                }
            })
    }

    renderForm = ({ values, touched, handleChange, errors, dirty, isSubmitting, setFieldValue }) => {
        if (isSubmitting === true)
        {
            return <Loading />
        }

        return (
            <Form>
                <Field name='franchise_id' component='input' type='hidden' className='form-control' />
                <div className='form-group'>
                    <label>Fornecedor</label>
                    <Select
                        value={this.state.formValues.supplier}
                        onChange={(selectedOption) => {
                            this.setState({ formValues: { ...this.state.formValues, supplier: selectedOption } })

                            const selectedSupplier = this.state.suppliers.filter((row) => {
                                return row.id == selectedOption.value
                            })[0]
                            this.setState({ selectedSupplier })
                            this.loadPaymentInfo(values.airline, selectedOption.value, setFieldValue)
                        }}
                        options={this.state.suppliersOptions}
                        onBlur={(event) => handleChange(event)}
                    />
                    {!this.state.formValues.supplier ? <div className='invalid-tooltip'>Selecione o fornecedor</div> : null }
                </div>
                <div className='form-group'>
                    <label>Companhia Aérea</label>
                    <Field
                        component='select'
                        name='airline'
                        className='form-control'
                        onChange={(event) => {
                            handleChange(event)
                            this.loadPaymentInfo(event.target.value, this.state.formValues.supplier.value, setFieldValue)
                        }}
                    >
                        <option value=''>Selecione a Companhia</option>
                        {this.state.airlines && this.state.airlines.map((row) => {
                            return <option key={row.id} value={row.id}>{row.name}</option>
                        })}
                    </Field>
                    <ErrorMessage name='airline' component='div' className='invalid-tooltip' />
                </div>
                <div className='form-group form-group--float'>
                    <Field
                        name='amount'
                        render={({ field }) => (
                            <CurrencyInput
                                {...field}
                                onChangeEvent={(event) => {
                                    field.onChange(event)
                                    this.changeTotal(event.target.value, values.price)
                                }}
                                precision='0'
                                className='form-control form-control--active'
                                thousandSeparator='.'
                            />
                        )}
                    />
                    <label>Pontuação comprada</label>
                    <ErrorMessage name='amount' component='div' className='invalid-tooltip' />
                </div>
                <div className='form-group form-group--float'>
                    <Field
                        name='price'
                        render={({ field /* { name, value, onChange, onBlur } */ }) => (
                            <CurrencyInput
                                {...field}
                                onChangeEvent={(event) => {
                                    field.onChange(event)
                                    this.changeTotal(values.amount, event.target.value)
                                }}
                                // onChangeEvent={(event, maskedvalue, floatvalue) => handleChange(event)}
                                precision='2'
                                prefix='R$ '
                                className='form-control form-control--active'
                                decimalSeparator=','
                                thousandSeparator='.'
                            />
                        )}
                    />
                    <label>Preço de compra</label>
                    <ErrorMessage name='price' component='div' className='invalid-tooltip' />
                </div>
                <div className='form-group form-group--float'>
                    <Field name='expiration_date' type='date' className='form-control form-control--active' />
                    <label>Data de Validade</label>
                    <ErrorMessage name='expiration_date' component='div' className='invalid-tooltip' />
                </div>
                <div className='form-group form-group--float'>
                    <Field name='number_cpfs' type='number' className={`form-control${values.number_cpfs !== '' ? ' form-control--active' : ''}`} />
                    <label>Número de CPFs <small>(Insira 999 caso não deseje limitar emissões por CPFs)</small></label>
                    <ErrorMessage name='number_cpfs' component='div' className='invalid-tooltip' />
                </div>
                <div className='form-group form-group--float'>
                    <Field name='days_to_pay' type='number' className={`form-control${values.days_to_pay !== '' ? ' form-control--active' : ''}`} />
                    <label>Dias para pagamento</label>
                    <ErrorMessage name='days_to_pay' component='div' className='invalid-tooltip' />
                </div>
                <div className='form-group form-group--float'>
                    <Field name='payment_instructions' component='textarea' className={`form-control${values.payment_instructions !== '' ? ' form-control--active' : ''}`} />
                    <label style={{ bottom: values.payment_instructions ? '3.25rem': '' }}>Dados bancários</label>
                    <ErrorMessage name='payment_instructions' component='div' className='invalid-tooltip' />
                </div>
                <div className='form-group form-group--float'>
                    <Field name='login' className={`form-control${values.login !== '' ? ' form-control--active' : ''}`} />
                    <label>Login</label>
                    <ErrorMessage name='login' component='div' className='invalid-tooltip' />
                </div>
                <div className='form-group form-group--float'>
                    <Field name='password' className={`form-control${values.password !== '' ? ' form-control--active' : ''}`} />
                    <label>Senha</label>
                    <ErrorMessage name='password' component='div' className='invalid-tooltip' />
                </div>
                <div className='form-group form-group--float'>
                    <Field name='redemption_password' className={`form-control${values.redemption_password !== '' ? ' form-control--active' : ''}`} />
                    <label>Senha de Resgate</label>
                    <ErrorMessage name='redemption_password' component='div' className='invalid-tooltip' />
                </div>

                <button
                    type='submit'
                    className='btn btn-primary'
                    disabled={isSubmitting || !isEmpty(errors) || !dirty}
                >
                    {isSubmitting ? 'Aguarde...' : 'Salvar'}
                </button>
            </Form>
        )
    }

    handleSubmit = (values, actions) => {
        if (!this.state.formValues.supplier)
        {
            actions.setSubmitting(false)
            return
        }

        const { airline } = values
        const { selectedSupplier } = this.state
        const supplier = this.state.formValues.supplier.value

        const _values = Object.assign({}, values)
        _values.amount = parseInt(_values.amount.replace(/\./g, ''))
        // _values.minimum_ticket_issue = parseInt(_values.minimum_ticket_issue.replace(/\./g, ''))
        _values.price = parseFloat(_values.price.replace(/R\$\ /g, '').replace(/\./g, '').replace(/\,/g, '.'))

        // TODO tirar codigos hardcoded
        API.post(
            'statements',
            {
                account_id: 9999,
                chart_of_account_id: 9999,
                days_to_pay: _values.days_to_pay,
                description: 'Compra de milhas',
                effective_date: 'now',
                received_from_paid_to: `${selectedSupplier.cpf} - ${selectedSupplier.name}`,
                value: (_values.price * (_values.amount / 1000))
            }
        )
        .then((response) => {
            return API.patch(
                `airlines/${airline}/suppliers/${supplier}/update-miles`,
                {
                    ..._values,
                    supplier,
                    movement_type: MovementType.CREDIT,
                    statement_id: response.data.data.id,
                    transaction_type: TransactionType.BUY_MILES,
                    temp_user_email: 'herman@initia.com.br'
                }
            )
        })
        .then((response) => {
            toast.success('Milhas adicionadas com sucesso!')
            this.setState({ redirectTo: '/supply' })
            actions.setSubmitting(false)
        })
        .catch((error) => {
            toast.error('Ocorreu um erro ao tentar processar os dados enviados')
            console.error(error)
            actions.setSubmitting(false)
        })
    }

    render() {
        if (this.state.redirectTo !== null)
        {
            return <Redirect to={this.state.redirectTo} />
        }

        return (
            <Formik
                initialValues={{
                    franchise_id: '1',
                    // supplier: '',
                    airline: '',
                    amount: '',
                    days_to_pay: '',
                    price: '',
                    // minimum_ticket_issue: '',
                    number_cpfs: '',
                    expiration_date: '',
                    payment_instructions: '',
                    login: '',
                    password: '',
                    redemption_password: ''

                    // franchise_id: '1',
                    // // supplier: '1',
                    // airline: '1',
                    // amount: '10000',
                    // days_to_pay: '',
                    // price: '25',
                    // minimum_ticket_issue: '2500',
                    // payment_instructions: 'banco itau',
                    // login: 'maria',
                    // password: 'do bairro',
                    // redemption_password: 'esqueci'
                }}
                validationSchema={AirlineSupplierSchema}
                onSubmit={this.handleSubmit}
                render={this.renderForm}
            />
        )
    }
}

export default AirlineSupplierFormik