import React, { Component } from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import isEmpty from 'lodash/isEmpty'
import MaskedInput from 'react-maskedinput'
import { Redirect } from 'react-router'
import { toast } from 'react-toastify'
import * as Yup from 'yup'

import Loading from '../../template/Loading'

import { API } from '../../../config/api'

const SupplierSchema = Yup.object().shape({
    name: Yup.string()
        .required('Informe o nome'),
    email: Yup.string()
        .required('Informe o e-mail')
        .email('Informe um e-mail válido'),
    cpf: Yup.string()
        .required('Informe o CPF')
        .matches(/[0-9]{3}\.[0-9]{3}\.[0-9]{3}\-[0-9]{2}/, 'Informe o CPF completo'),
    phone: Yup.string()
        .required('Informe o telefone')
        .matches(/\([0-9]{2}\)\ 9[0-9]{4}\-[0-9]{4}/, 'Celular inválido'),
    telephone: Yup.string()
        .matches(/\([0-9]{2}\)\ [0-9]{4}\-[0-9]{4}/, 'Telefone inválido')
})

class SupplierFormik extends Component {
    constructor(props) {
        super(props)

        this.state = {
            redirectTo: null
        }
    }

    renderForm = ({ values, touched, errors, dirty, isSubmitting }) => {
        if (isSubmitting === true)
        {
            return <Loading />
        }

        return (
            <Form>
                <div className='form-group form-group--float'>
                    <Field name='name' className={`form-control${values.name !== '' ? ' form-control--active' : ''}`} />
                    <label>Nome</label>
                    <ErrorMessage name='name' component='div' className='invalid-tooltip' />
                </div>
                <div className='form-group form-group--float'>
                    <Field name='email' className={`form-control${values.email !== '' ? ' form-control--active' : ''}`} />
                    <label>E-mail</label>
                    <ErrorMessage name='email' component='div' className='invalid-tooltip' />
                </div>
                <div className='form-group form-group--float'>
                    <Field
                        name='phone'
                        render={({ field }) => (
                            <MaskedInput
                                {...field}
                                className='form-control form-control--active'
                                mask="(11) 11111-1111"
                                onChangeEvent={field.onChange}
                            />
                        )}
                    />
                    <label>Celular</label>
                    <ErrorMessage name='phone' component='div' className='invalid-tooltip' />
                </div>
                <div className='form-group form-group--float'>
                    <Field
                        name='telephone'
                        render={({ field }) => (
                            <MaskedInput
                                {...field}
                                className='form-control form-control--active'
                                mask="(11) 1111-1111"
                                onChangeEvent={field.onChange}
                            />
                        )}
                    />
                    <label>Telefone</label>
                    <ErrorMessage name='telephone' component='div' className='invalid-tooltip' />
                </div>
                <div className='form-group form-group--float'>
                    <Field
                        name='cpf'
                        render={({ field }) => (
                            <MaskedInput
                                {...field}
                                className='form-control form-control--active'
                                mask="111.111.111-11"
                                onChangeEvent={field.onChange}
                            />
                        )}
                    />
                    <label>CPF</label>
                    <ErrorMessage name='cpf' component='div' className='invalid-tooltip' />
                </div>

                <button
                    type='submit'
                    className='btn btn-primary'
                    disabled={isSubmitting || !isEmpty(errors) || !dirty}
                >
                    {isSubmitting ? 'Aguarde...' : 'Salvar'}
                </button>
            </Form>
        )
    }

    handleSubmit = (values, actions) => {
        const { id } = this.props.supplier
        const method = id ? 'patch' : 'post'
        const urlSuffix = id ? `/${id}` : ''
        const url = `suppliers${urlSuffix}`

        API[method](url, values)
            .then((response) => {
                toast.success(`Registro ${id ? 'alterado' : 'inserido'} com sucesso!`)
                this.setState({
                    redirectTo: '/supply/suppliers'
                })
                actions.setSubmitting(false)
            })
            .catch((error) => {
                console.error(error)
                toast.error('Ocorreu um erro ao tentar salvar o registro')
            })
    }

    render() {
        if (this.state.redirectTo !== null)
        {
            return <Redirect to={this.state.redirectTo} />
        }

        return (
            <Formik
                initialValues={{
                    name: this.props.supplier.name || '',
                    cpf: this.props.supplier.cpf || '',
                    phone: this.props.supplier.phone || '',
                    telephone: this.props.supplier.telephone || '',
                    email: this.props.supplier.email || ''
                }}
                validationSchema={SupplierSchema}
                onSubmit={this.handleSubmit}
                render={this.renderForm}
            />
        )
    }
}

export default SupplierFormik