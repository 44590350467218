import React, { Component } from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import isEmpty from 'lodash/isEmpty'
import CurrencyInput from 'react-currency-input'
import { Redirect } from 'react-router'
import Select from 'react-select'
import { toast } from 'react-toastify'
import * as Yup from 'yup'

import TransactionType from '../../../enums/TransactionType'
import MovementType from '../../../enums/MovementType'

import Loading from '../../template/Loading'

import { API } from '../../../config/api'

const AirlineSupplierSchema = Yup.object().shape({
    airline: Yup.string()
        .required('Selecione a companhia'),
    amount: Yup.string()
        .when('number_cpfs', {
            is: (val) => val !== undefined,
            then: Yup.string().notRequired(),
            otherwise: Yup.string().required('Informe a quantidade de milhas e/ou de CPFs'),
        }),
    number_cpfs: Yup.string()
        .when('amount', {
            is: (val) => val !== undefined,
            then: Yup.string().notRequired(),
            otherwise: Yup.string().required('Informe a quantidade de milhas e/ou de CPFs'),
        }),
    price: Yup.string()
        .when('amount', {
            is: (val) => val === undefined,
            then: Yup.string().notRequired(),
            otherwise: Yup.string().required('Informe o preço')
        }),
    //expiration_date: Yup.string()
    //    .when(['amount', 'movement_type'], {
    //        is: (amount, movement_type) => amount && movement_type === 'C',
    //        then: Yup.string().required('Informe a Data de Validade'),
    //        otherwise: Yup.string().notRequired()
    //    }),
    comments: Yup.string()
        .required('Informe o motivo do reajuste')
        .min(5, 'Informe o motivo do reajuste')
}, ['amount', 'number_cpfs', ['amount', 'movement_type']])

class AdjustBalanceFormik extends Component {
    constructor(props) {
        super(props)

        this.state = {
            airlines: [],
            suppliers: [],
            suppliersOptions: [],

            formValues: {
                supplier: ''
            },

            redirectTo: null
        }
    }

    componentDidMount = () => {
        this.loadAirlines()
        this.loadSuppliers()
    }

    loadAirlines = () => {
        API.get('airlines')
            .then((response) => {
                if (response.status === 200)
                {
                    this.setState({
                        airlines: response.data.data
                    })
                }
            })
    }

    loadSuppliers = () => {
        API.get('suppliers')
            .then((response) => {
                if (response.status === 200)
                {
                    return this.setState({ suppliers: response.data.data })
                }
            })
            .then((response) => {
                const suppliersOptions = this.state.suppliers && this.state.suppliers.map((row) => {
                    return {value: row.id, label: row.name}
                })
                this.setState({ suppliersOptions })
            })
    }

    renderForm = ({ values, touched, handleChange, errors, dirty, isSubmitting, setFieldValue }) => {
        if (isSubmitting === true)
        {
            return <Loading />
        }

        return (
            <Form>
                <Field name='franchise_id' component='input' type='hidden' className='form-control' />
                <div className='form-group'>
                    <label>Fornecedor</label>
                    <Select
                        value={this.state.formValues.supplier}
                        onChange={(selectedOption) => {
                            this.setState({ formValues: { ...this.state.formValues, supplier: selectedOption } })
                        }}
                        options={this.state.suppliersOptions}
                        onBlur={(event) => handleChange(event)}
                    />
                    {!this.state.formValues.supplier ? <div className='invalid-tooltip'>Selecione o fornecedor</div> : null }
                </div>
                <div className='form-group'>
                    <label>Companhia Aérea</label>
                    <Field
                        component='select'
                        name='airline'
                        className='form-control'
                    >
                        <option value=''>Selecione</option>
                        {this.state.airlines && this.state.airlines.map((row) => {
                            return <option key={row.id} value={row.id}>{row.name}</option>
                        })}
                    </Field>
                    <ErrorMessage name='airline' component='div' className='invalid-tooltip' />
                </div>
                <div className='form-group'>
                    <label>Tipo de Ajuste</label>
                    <Field
                        component='select'
                        name='movement_type'
                        className='form-control'
                    >
                        <option value=''>Selecione</option>
                        <option value={MovementType.CREDIT}>Adicionar Milhas e/ou CPFs</option>
                        <option value={MovementType.DEBT}>Remover Milhas e/ou CPFs</option>
                    </Field>
                    <ErrorMessage name='movement_type' component='div' className='invalid-tooltip' />
                </div>
                <div className='form-group form-group--float'>
                    <Field
                        name='amount'
                        render={({ field }) => (
                            <CurrencyInput
                                {...field}
                                onChangeEvent={field.onChange}
                                precision='0'
                                className='form-control form-control--active'
                                thousandSeparator='.'
                            />
                        )}
                    />
                    <label>Quantidade de Milhas</label>
                    <ErrorMessage name='amount' component='div' className='invalid-tooltip' />
                </div>
                <div className={ `form-group form-group--float ${(values.movement_type === 'C' && values.amount) ? '' : 'd-none'} ` }>
                    <Field name='expiration_date' type='date' className='form-control form-control--active' />
                    <label>Data de Validade</label>
                    <ErrorMessage name='expiration_date' component='div' className='invalid-tooltip' />
                </div>
                <div className='form-group form-group--float'>
                    <Field
                        name='number_cpfs'
                        render={({ field }) => (
                            <CurrencyInput
                                {...field}
                                onChangeEvent={field.onChange}
                                precision='0'
                                className='form-control form-control--active'
                                thousandSeparator='.'
                            />
                        )}
                    />
                    <label>Quantidade de CPFs</label>
                    <ErrorMessage name='number_cpfs' component='div' className='invalid-tooltip' />
                </div>
                <div className='form-group form-group--float'>
                    <Field
                        name='price'
                        render={({ field /* { name, value, onChange, onBlur } */ }) => (
                            <CurrencyInput
                                {...field}
                                onChangeEvent={field.onChange}
                                // onChangeEvent={(event, maskedvalue, floatvalue) => handleChange(event)}
                                precision='2'
                                prefix='R$ '
                                className='form-control form-control--active'
                                decimalSeparator=','
                                thousandSeparator='.'
                            />
                        )}
                    />
                    <label>Preço</label>
                    <ErrorMessage name='price' component='div' className='invalid-tooltip' />
                </div>
                <div className='form-group form-group--float'>
                    <Field name='comments' component='textarea' className={`form-control${values.comments !== '' ? ' form-control--active' : ''}`} />
                    <label style={{ bottom: values.comments !== '' ? '3.25rem': '' }}>Informe a justificativa para esse ajuste</label>
                    <ErrorMessage name='comments' component='div' className='invalid-tooltip' />
                </div>

                <button
                    type='submit'
                    className={`btn btn-${(!isEmpty(errors) || !dirty) ? 'secondary' : values.movement_type == 'C' ? 'success' : 'danger'}`}
                    disabled={isSubmitting || !isEmpty(errors) || !dirty}
                >
                    {isSubmitting
                        ? 'Aguarde...'
                        : (!isEmpty(errors) || !dirty)
                            ? 'Aguardando preenchimento do formulário'
                            : `${values.movement_type == 'C' ? 'Adicionar' : 'Remover'} ${values.amount !== '' ? values.amount : 0} milhas e ${values.number_cpfs !== '' ? values.number_cpfs : 0} CPFs para o fornecedor selecionado`
                    }
                </button>
            </Form>
        )
    }

    handleSubmit = (values, actions) => {
        if (!this.state.formValues.supplier)
        {
            actions.setSubmitting(false)
            return
        }

        const { airline } = values
        const supplier = this.state.formValues.supplier.value

        const _values = Object.assign({}, values)
        _values.amount = parseInt(_values.amount.replace(/\./g, ''))
        _values.number_cpfs = parseInt(_values.number_cpfs.replace(/\./g, ''))
        _values.price = parseFloat(_values.price.replace(/R\$\ /g, '').replace(/\./g, '').replace(/\,/g, '.'))

        API.patch(
            `airlines/${airline}/suppliers/${supplier}/update-miles`,
            {
                ..._values,
                supplier,
                transaction_type: TransactionType.ADJUST_BALANCE,
                temp_user_email: 'herman@initia.com.br'
            }
        )
        .then((response) => {
            if (response.status === 204)
            {
                toast.success('Saldo ajustado com sucesso!')
                this.setState({
                    redirectTo: '/supply'
                })
            }
            actions.setSubmitting(false)
        })
        .catch((error) => {
            toast.error('Ocorreu um erro ao tentar processar o ajuste')
            console.error(error)
        })
    }

    render() {
        if (this.state.redirectTo !== null)
        {
            return <Redirect to={this.state.redirectTo} />
        }

        return (
            <Formik
                initialValues={{
                    franchise_id: '1',
                    airline: '',
                    amount: '',
                    number_cpfs: '',
                    price: '',
                    comments: ''
                }}
                validationSchema={AirlineSupplierSchema}
                onSubmit={this.handleSubmit}
                render={this.renderForm}
            />
        )
    }
}

export default AdjustBalanceFormik