import React, { Component } from 'react'
import moment from 'moment'

import { API } from '../../../config/api'

import TransactionType from '../../../enums/TransactionType'

import ResponsiveTable from '../../template/tables/ResponsiveTable'
import Card from '../../template/Card'
import ContentTitle from '../../template/ContentTitle'
import Loading from '../../template/Loading'

class StatementSupplier extends Component {
    constructor(props) {
        super(props)

        this.state = {
            'rows': [],
            'loading': true
        }
    }

    componentDidMount = () => {
        document.title = 'Extrato'
        this.loadSuppliers()
    }

    componentWillReceiveProps(nextProps){
        this.loadSuppliers()
    }

    loadSuppliers = async () => {
        await this.setState({ loading: true, rows: [] })
        API.get('statement-suppliers')
            .then((resultJson) => {
                if (resultJson.status === 200)
                {
                    this.setState({
                        loading: false,
                        rows: resultJson.data.data
                    })
                }
            })
    }

    renderType = (statementSupplier) => {
        if (statementSupplier.transaction_type === TransactionType.BUY_MILES)
        {
            return <span className="badge badge-success">Compra de Milhas</span>
        }

        if (statementSupplier.transaction_type === TransactionType.TICKET_EMISSION)
        {
            return <span className="badge badge-danger">Venda</span>
        }

        if (statementSupplier.transaction_type === TransactionType.ADJUST_BALANCE)
        {
            return <span className="badge badge-warning">Ajuste de saldo</span>
        }

        return <span className="badge badge-secondary">Indefinido</span>
    }

    renderRow = (statementSupplier) => {
        if (statementSupplier.movement_type === 'C')
        {
            return (
                <tr key={statementSupplier.id}>                    
                    <th scope="row">{statementSupplier.id}</th>
                    <td>{this.renderType(statementSupplier)}</td>
                    <td>{statementSupplier.temp_op_id || '-'}</td>
                    <td>{statementSupplier.supplier.cpf}</td>
                    <td>{statementSupplier.supplier.name}</td>
                    <td>{statementSupplier.airline.name}</td>
                    <td>{statementSupplier.miles_br && statementSupplier.miles_br !== '0' ? statementSupplier.miles_br : '-'}</td>
                    <td>{statementSupplier.expiration_date ? moment(statementSupplier.expiration_date).format('DD/MM/YYYY') : '-'}</td>
                    <td>R$ {statementSupplier.price_br}</td>
                    <td>{statementSupplier.number_cpfs && statementSupplier.number_cpfs !== '0' ? statementSupplier.number_cpfs : '-'}</td>
                    <td>{moment(statementSupplier.created_at).format('DD/MM/YYYY HH:mm:ss')}</td>
                    <td>{statementSupplier.transation_type === TransactionType.TICKET_EMISSION ? statementSupplier.booking_code : statementSupplier.comments}</td>
                </tr>
            )
        }

        if (statementSupplier.movement_type === 'D')
        {
            return (
                <tr key={statementSupplier.id}>
                    <th scope="row">{statementSupplier.id}</th>
                    <td>{this.renderType(statementSupplier)}</td>
                    <td>{statementSupplier.temp_op_id || '-'}</td>
                    <td>{statementSupplier.supplier.cpf}</td>
                    <td>{statementSupplier.supplier.name}</td>
                    <td>{statementSupplier.airline.name}</td>
                    <td>{statementSupplier.miles_br && statementSupplier.miles_br !== '0' ? '-' + statementSupplier.miles_br : '-'}</td>
                    <td>-</td>
                    <td>R$ {statementSupplier.price_br}</td>
                    <td>{statementSupplier.number_cpfs && statementSupplier.number_cpfs !== '0' ? '-' + statementSupplier.number_cpfs : '-'}</td>
                    <td>{moment(statementSupplier.created_at).format('DD/MM/YYYY HH:mm:ss')}</td>
                    <td>{statementSupplier.transation_type === TransactionType.TICKET_EMISSION ? statementSupplier.booking_code : statementSupplier.comments}</td>
                </tr>
            )
        }
    }

    render = () => {
        return (
            <React.Fragment>
                <ContentTitle title='Estoque' />
                <Card
                    title='Extrato'
                    subtitle=''
                >
                    {
                        this.state.loading ?
                        <Loading /> :
                        <ResponsiveTable
                            headers={['Id', 'Tipo', 'OP', 'CPF', 'Fornecedor', 'Companhia', 'Pontuação', 'Validade', 'Preço', 'CPFs', 'Data', 'Observação']}
                            rows={this.state.rows}
                            renderRow={this.renderRow}
                        />
                    }
                </Card>
            </React.Fragment>
        )
    }
}

export default StatementSupplier