import React from 'react'

const noAvatar = 'http://www.unialfa.com.br/tudounialfa/img/semfoto.png'

export default props => (
    <div className="user">
        <div className="user__info" data-toggle="dropdown">
            {/* AOC
                <img className="user__img" src={props.user.avatar ? props.user.avatar : noAvatar} alt="" />
            */}
            <div>
                <div className="user__name">{props.user.name}</div>
                <div className="user__email">{props.user.email}</div>
            </div>
        </div>

        <div className="dropdown-menu">
            <a className="dropdown-item" href="/">View Profile</a>
            <a className="dropdown-item" href="/">Settings</a>
            <a className="dropdown-item" href="/">Logout</a>
        </div>
    </div>
)

