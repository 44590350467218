import React from 'react'

const BasicTable = (props) => (
    <table className={props.tableClass || 'table mb-0'}>
        <thead>
            <tr>
                {props.headers && props.headers.map((header, index) => {
                    return <th key={index}>{header}</th>
                })}
            </tr>
        </thead>
        <tbody>
            {props.rows && props.rows.map((row, index) => {
                return props.renderRow(row, index)
            })}
        </tbody>
    </table>
)

export default BasicTable