import React, { Component } from 'react'

import { API } from '../../config/api'

import ContentTitle from '../template/ContentTitle'
import CardBox from '../template/widgets/CardBox'
import Loading from '../template/Loading'

import './Dashboard.css'

import logoAvianca from '../../assets/img/logos/logo-avianca.png'
import logoGol from '../../assets/img/logos/logo-gol.png'
import logoLatam from '../../assets/img/logos/logo-latam.png'
import logoVoeAzul from '../../assets/img/logos/logo-voeazul.png'

class Dashboard extends Component {
    constructor(props) {
        super(props)

        this.state = {
            'loading': true,
            summary: [],
            info: {
                3: {name: 'GOL', min: 500000, min_br: '300.000', value: '21,74', logo: logoGol},
                4: {name: 'GOL - Diamante', min: 500000, min_br: '500.000', value: '22,83', logo: logoGol},
                5: {name: 'LATAM', min: 2000000, min_br: '2.000.000', value: '29,13', logo: logoLatam},
                6: {name: 'LATAM - Platinum', min: 500000, min_br: '500.000', value: '32,04', logo: logoLatam},
            }
        }
    }

    componentWillMount = () => {
        document.title = 'Dashboard'
    }

    componentDidMount = () => {
        this.loadSummary()
    }

    loadSummary = async () => {
        await this.setState({ loading: true, summary: [] })
        API.get('airline-suppliers-summary')
            .then((resultJson) => {
                if (resultJson.status === 200)
                {
                    this.setState({
                        loading: false,
                        summary: resultJson.data.data
                    })
                    console.log(resultJson.data.data)
                }
            })
    }

    renderAlerts = () => {
        return (
            <React.Fragment>
                {this.state.summary.map((row, index) => {
                    if (row.airline_id != 1 && row.airline_id != 2 && row.airline_id) {
                        if (row.active_miles < this.state.info[row.airline_id].min)
                        {
                            return (
                                <div key={index} className="alert alert-danger text-center" role="alert">
                                    O estoque de milhas ativas da {row.airline_name} está abaixo do mínimo
                                </div>
                            )
                        }
                    }
                    return null
                })}
            </React.Fragment>
        )
    }

    renderCards = () => {
        return (
            <React.Fragment>
                <div className="row quick-stats">
                    {this.state.summary.map((row, index) => {
                        if (row.airline_id != 1 && row.airline_id != 2) {
                            return (
                                <CardBox
                                    key={index}
                                    bgColor='bg-secondary'
                                    className='col-sm-6 col-md-3'
                                    title={this.state.info[row.airline_id].name}
                                    label_1='Estoque mínimo'
                                    value_1={this.state.info[row.airline_id].min_br}
                                    label_2='Custo médio ideal'
                                    value_2={`R$ ${this.state.info[row.airline_id].value}`}
                                >
                                    <img src={this.state.info[row.airline_id].logo} className='img-card' />
                                </CardBox>
                            )
                        }
                    })}
                </div>
            </React.Fragment>
        )
    }

    render = () => {
        if (this.state.loading === true)
        {
            return <Loading />
        }

        return (
            <React.Fragment>
                <ContentTitle title='Dashboard' />
                {this.renderAlerts()}
                {this.renderCards()}
            </React.Fragment>
        )
    }
}

export default Dashboard