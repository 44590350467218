import React from 'react'

const CardBox = (props) => (
    <div className={props.className || 'col-sm-6 col-md-3'}>
        <div className={`quick-stats__item ${props.bgColor || 'bg-blue'}`}>
            <div className="quick-stats__info">
                {
                    props.title ?
                    <h2 class="mb-2">{props.title}</h2>
                    : null
                }
                <small>{props.label_1}: </small>
                <h2>{props.value_1}</h2>
                <small>{props.label_2}: </small>
                <h2>{props.value_2}</h2>
            </div>

            <div className="quick-stats__chart sparkline-bar-stats">
                {props.children}
            </div>
        </div>
    </div>
)

export default CardBox