import React from 'react'
import jwt_decode from 'jwt-decode'

import UserInfo from './UserInfo'
import Menu from './Menu'
import MenuItem from './MenuItem'
import Submenu from './Submenu'

const Side = (props) => {
    const { avatar, email, name } = jwt_decode(localStorage.getItem('jwtToken'))
    const userInfo = {
        name: name,
        email: email,
        avatar: avatar
    }

    return (
        <aside className={`sidebar${props.toggled ? ' toggled' : ''}`}>
            <div className="scrollbar-inner">
                <UserInfo
                    user={userInfo}
                />
                <Menu>
                    <MenuItem toggleMenuHandler={props.toggleMenuHandler} to='/' icon='home' label='Dashboard' />
                    <Submenu icon='chart' label='Estoque'>
                        <MenuItem toggleMenuHandler={props.toggleMenuHandler} to='/supply/airlines' label='Companhias' />
                        <MenuItem toggleMenuHandler={props.toggleMenuHandler} to='/supply/suppliers' label='Fornecedores' />
                        <MenuItem toggleMenuHandler={props.toggleMenuHandler} to='/supply' label='Estoque completo' />
                        <MenuItem toggleMenuHandler={props.toggleMenuHandler} to='/supply/summary' label='Sumário' />
                        <MenuItem toggleMenuHandler={props.toggleMenuHandler} to='/supply/statement-suppliers' label='Extrato' />
                    </Submenu>

                    <Submenu icon='money-box' label='Financeiro'>
                        <MenuItem toggleMenuHandler={props.toggleMenuHandler} to='/finance/statement' label='Extrato Geral' />
                        <MenuItem toggleMenuHandler={props.toggleMenuHandler} to='/finance/statement' label='Exportar Relatório' />
                    </Submenu>

                    <Submenu icon='trending-up' label='Relatorios'>
                        <MenuItem toggleMenuHandler={props.toggleMenuHandler} to='/reports/airline-suppliers/low-balance' label='Estoque baixo' />
                    </Submenu>

                    {/* <MenuItem toggleMenuHandler={props.toggleMenuHandler} to='/supply/airline-supplier' icon='chart' label='Registrar entrada' /> */}
                    {/* <MenuItem toggleMenuHandler={props.toggleMenuHandler} to='/supply/airline-supplier/adjust-balance' icon='chart' label='Ajustar saldo' /> */}

                    <li>
                        <a
                            href="#/"
                            onClick={props.handleLogout}
                        >
                            <i className={'zmdi zmdi-power'}></i> Sair
                        </a>
                    </li>
                </Menu>
            </div>
        </aside>
    )
}

export default Side