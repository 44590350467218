import React from 'react'
import { Switch, Route, Redirect } from 'react-router'

import Dashboard from '../components/dashboard/Dashboard'

import Statement from '../components/finance/statement/Statement'

import AdjustBalance from '../components/supply/airlineSupplier/AdjustBalance'
import Airline from '../components/supply/airline/Airline'
import AirlineSupplier from '../components/supply/airlineSupplier/AirlineSupplier'
import StatementSupplier from '../components/supply/statementSupplier/StatementSupplier'
import Summary from '../components/supply/summary/Summary'
import Supplier from '../components/supply/supplier/Supplier'
import SupplierForm from '../components/supply/supplier/SupplierForm'
import Supply from '../components/supply/supply/Supply'

import ReportAirlineSuppliersLowBalance from '../components/reports/airlineSuppliers/LowBalance'

const Routes = () => (
    <Switch>
        <Route exact path='/dashboard' component={Dashboard} />

        <Route path='/finance/statement' component={Statement} />

        <Route path='/supply/suppliers' exact component={Supplier} />
        <Route path='/supply/suppliers/create' component={SupplierForm} />
        <Route path='/supply/suppliers/update/:id' component={SupplierForm} />
        <Route path='/supply/airlines' exact component={Airline} />
        <Route path='/supply/airline-supplier' exact component={AirlineSupplier} />
        <Route path='/supply/airline-supplier/adjust-balance' component={AdjustBalance} />
        <Route path='/supply' exact component={Supply} />
        <Route path='/supply/statement-suppliers' component={StatementSupplier} />
        <Route path='/supply/summary' component={Summary} />

        <Route path='/reports/airline-suppliers/low-balance' component={ReportAirlineSuppliersLowBalance} />

        <Redirect from='*' to='/dashboard' />
    </Switch>
)

export default Routes