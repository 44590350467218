import React from 'react'

import BasicTable from './BasicTable'

const ResponsiveTable = (props) => (
    <div className="table-responsive">
        <BasicTable {...props} tableClass='table table-bordered mb-0' />
    </div>
)

export default ResponsiveTable