import React from 'react'

const TopNav = () => (
    <ul className="top-nav">
        <li className="hidden-xl-up"><a href="/" data-ma-action="search-open"><i className="zmdi zmdi-search"></i></a></li>
        <li className="hidden-xs-down">
            <a href="/" data-ma-action="aside-open" data-ma-target=".chat" className="top-nav__notify">
                <i className="zmdi zmdi-comment-alt-text"></i>
            </a>
        </li>
    </ul>
)

export default TopNav