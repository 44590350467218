import React from 'react'

import Button from './Button'

const IconButton = (props) => (
    <Button {...props} buttonClasses={`btn--icon btn-${props.color}`}>
        <i className={`zmdi zmdi-${props.icon}`}></i>
    </Button>
)

export default IconButton