import React, { Component } from 'react'

import Card from '../../template/Card'
import ContentTitle from '../../template/ContentTitle'

import AirlineSupplierFormik from './AirlineSupplierFormik'

class AirlineSupplier extends Component {
    constructor(props) {
        super(props)

        this.state = {
            total: 0.00
        }
    }

    componentDidMount = () => {
        document.title = 'Comprar Milhas'
    }

    changeTotal = (total) => {
        this.setState({ total })
    }

    render = () => {
        return (
            <React.Fragment>
                <ContentTitle title='Estoque' />
                <Card
                    title={`Comprar Milhas - Valor total : R$${this.state.total}`}
                    subtitle=''
                >
                    <AirlineSupplierFormik changeTotal={this.changeTotal} />
                </Card>
            </React.Fragment>
        )
    }
}

export default AirlineSupplier