import React from 'react'

import './Button.css'

const Button = (props) => (
    <button onClick={props.handleClick} className={`btn ${props.buttonClasses}`}>
        {props.children}
    </button>
)

export default Button