import React, { Component } from 'react'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { Button, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { toast } from 'react-toastify'

import { API } from '../../../config/api'

import ResponsiveTable from '../../template/tables/ResponsiveTable'
import Card from '../../template/Card'
import ContentTitle from '../../template/ContentTitle'
import IconButton from '../../template/buttons/IconButton'
import Loading from '../../template/Loading'
import {FaExclamationTriangle} from 'react-icons/fa'

import './Supply.css'

function sleep (miliseconds) {
    return new Promise((resolve) => setTimeout(resolve, miliseconds))
}

class Supply extends Component {
    constructor(props) {
        super(props)

        this.state = {
            'airlines': [],
            'rows': [],
            'loading': true,

            modal: {
                airlineSupplier: {},
                blockReasonId: 0,
                show: false,
                text: '',
                textButton: '',
                title: ''
            }
        }
    }

    componentDidMount = () => {
        document.title = 'Estoque completo'
        this.loadSuppliers()
    }

    componentWillReceiveProps(nextProps){
        this.loadSuppliers()
    }

    loadSuppliers = async () => {
        await this.setState({ loading: true, rows: [] })
        API.patch('unlock-expired-suppliers')
            .then(() => API.get('airline-suppliers'))
            .then((resultJson) => {
                if (resultJson.status === 200)
                {
                    this.setState({
                        loading: false,
                        rows: resultJson.data.data.map((row) => {
                            row.showBlockReasons = false
                            return row
                        })
                    })
                }
            })
    }

    renderStatus = (airlineSupplier) => {
        if (airlineSupplier.blocked === 1)
        {
            return <span className="badge badge-danger" href="#popover">Bloqueado</span>
        }

        if (airlineSupplier.in_use)
        {
            return <span className="badge badge-warning">Em uso OP {airlineSupplier.in_use}</span>
        }

        return <span className="badge badge-success">Disponível</span>
    }

    hideModal = () => {
        this.setState({ ...this.state, modal: { ...this.state.modal, show: false } })
    }

    showModal = (airlineSupplier, blockReasonId, blockReasonReason) => {
        let text = ''
        let textButton = ''
        let title = ''

        if (airlineSupplier.blocked)
        {
            text = (
                <div>
                    Você tem certeza que deseja desbloquear
                    as milhas {airlineSupplier.airline.name}
                    &nbsp;do fornecedor {airlineSupplier.supplier.name}?
                    <br /><br />O motivo do bloqueio foi:
                    <br /><strong className='text-danger'>{blockReasonReason}</strong>
                </div>
            )
            textButton = 'Confirmar o desbloqueio'
            title = 'Desbloquear fornecedor'
        }
        else
        {
            text = (
                <div>
                    Você confirma o bloqueio
                    das milhas {airlineSupplier.airline.name}
                    &nbsp;do fornecedor {airlineSupplier.supplier.name}
                </div>
            )
            textButton = 'Confirmar o bloqueio'
            title = 'Bloquear fornecedor'
        }

        this.setState({
            ...this.state,
            modal: {
                airlineSupplier,
                blockReasonId,
                show: true,
                text,
                textButton,
                title
            }
        })
    }

    toggleShowBlockReasons = (index) => {
        const rows = this.state.rows.map((row, idx) => {
            if (idx === index)
            {
                row.showBlockReasons = !row.showBlockReasons
            }

            return row
        })
        this.setState({ ...this.state, rows })
    }

    renderRow = (airlineSupplier, index) => {
        let warningIcon = "<i></i>"
        let minEmission
        switch (airlineSupplier.remaining_cpfs) {
            case null:
                minEmission = '-'
                break;
            case 0:
                minEmission = '-'
                break;
            case 999:
                minEmission = 0
                break;
            case 1:
                if ((airlineSupplier.miles - airlineSupplier.airline.minimum_stock_per_supplier) >= 1) {
                    minEmission = airlineSupplier.miles - airlineSupplier.airline.minimum_stock_per_supplier
                }
                else {
                    minEmission = 1
                }
                break;
            default:
                minEmission = Number(parseFloat(airlineSupplier.miles/airlineSupplier.remaining_cpfs*0.9).toFixed()).toLocaleString('pt-BR')
                break;
        }
        return (
            <tr key={airlineSupplier.id}>
                <th scope="row">{airlineSupplier.id}</th>
                <td className="tdSupplierName">{(!airlineSupplier.is_active && airlineSupplier.miles_br != '0') ? <FaExclamationTriangle /> : ''} {airlineSupplier.supplier ? airlineSupplier.supplier.name : ''}</td>
                <td>{airlineSupplier.supplier ? airlineSupplier.supplier.cpf : ''}</td>
                <td>{airlineSupplier.miles_br}</td>
                <td>{airlineSupplier.remaining_cpfs || '-'}</td>
                <td>{airlineSupplier.expiration_date ? moment(airlineSupplier.expiration_date).format('DD/MM/YYYY') : '-'}</td>
                <td>{airlineSupplier.airline ? airlineSupplier.airline.name : ''}</td>
                <td>{minEmission}</td>
                <td>R$ {airlineSupplier.price_br}</td>
                <td className="tdActions">
                    {this.renderStatus(airlineSupplier)}

                    {
                        airlineSupplier.blocked
                        ?
                        <IconButton
                            icon='undo'
                            color='primary'
                            handleClick={() => this.showModal(airlineSupplier, airlineSupplier.block_reason.id, airlineSupplier.block_reason.reason)}
                        />
                        :
                        <ButtonDropdown isOpen={this.state.rows[index].showBlockReasons} toggle={() => this.toggleShowBlockReasons(index)}>
                            <DropdownToggle caret className='btn--icon btn-danger'>
                                <i className='zmdi zmdi-block'></i>
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem header>Selecione o motivo do bloqueio</DropdownItem>
                                <DropdownItem divider />
                                <DropdownItem onClick={ () => sleep(200).then(() => this.showModal(airlineSupplier, 1)) }>Login e senha incorretos</DropdownItem>
                                <DropdownItem onClick={ () => sleep(200).then(() => this.showModal(airlineSupplier, 2)) }>Sem telefone cadastrado</DropdownItem>
                                <DropdownItem onClick={ () => sleep(200).then(() => this.showModal(airlineSupplier, 3)) }>CPF inválido</DropdownItem>
                                <DropdownItem onClick={ () => sleep(200).then(() => this.showModal(airlineSupplier, 4)) }>Conta bloqueada</DropdownItem>
                                <DropdownItem onClick={ () => sleep(200).then(() => this.showModal(airlineSupplier, 5)) }>Conta próxima do bloqueio</DropdownItem>
                                <DropdownItem onClick={ () => sleep(200).then(() => this.showModal(airlineSupplier, 6)) }>Aguardando auditoria</DropdownItem>
                                <DropdownItem onClick={ () => sleep(200).then(() => this.showModal(airlineSupplier, 7)) }>Aguardando retorno do financeiro</DropdownItem>
                            </DropdownMenu>
                        </ButtonDropdown>
                    }
                </td>
            </tr>
        )
    }

    toggleBlockSupplier = async () => {
        const { blocked } = this.state.modal.airlineSupplier
        let url = ''

        url = `airline-suppliers/${this.state.modal.airlineSupplier.id}`
        url += `/${blocked ? 'un' : ''}block?`
        url += `temp_by_email=temp@temp.com`
        url += blocked ? '' : `&block_reason_id=${this.state.modal.blockReasonId}`

        await this.setState({
            ...this.state,
            loading: true,
            rows: [],
            modal: {
                ...this.state.modal,
                show: false
            }
        })

        API.patch(url).then(() => {
            toast.success(`Fornecedor ${blocked ? 'desbloqueado' : 'bloqueado'} com sucesso!`)
            this.loadSuppliers()
        })
        .catch((error) => {
            toast.error(`Ocorreu um erro ao tentar ${blocked ? 'desbloquear' : 'bloquear'} o fornecedor`)
        })
    }

    ModalToggleBlock = () => {
        return (
            <Modal isOpen={this.state.modal.show} toggle={this.hideModal} className={this.props.className}>
                <ModalHeader toggle={this.hideModal}>{this.state.modal.title}</ModalHeader>
                <ModalBody>
                    {this.state.modal.text}
                </ModalBody>
                <ModalFooter>
                    <Button color="danger" onClick={() => this.toggleBlockSupplier()}>{this.state.modal.textButton}</Button>{' '}
                    <Button color="secondary" onClick={this.hideModal}>Cancelar</Button>
                </ModalFooter>
            </Modal>
        )
    }

    render = () => {
        return (
            <React.Fragment>
                {this.ModalToggleBlock()}
                <ContentTitle title='Estoque' />
                <Card
                    title='Estoque completo'
                    subtitle=''
                >
                    {
                        this.state.loading ?
                        <Loading /> :
                        <React.Fragment>
                            <div className='pb-4'>
                                <Link to='/supply/airline-supplier'>
                                    <button className='btn btn-success'>Comprar Milhas</button>
                                </Link>
                                <Link to='/supply/airline-supplier/adjust-balance'>
                                    <button className='btn btn-primary'>Ajustar Saldo</button>
                                </Link>
                            </div>
                            <ResponsiveTable
                                headers={['ID', 'Nome', 'CPF', 'Milhas', 'CPFs a emitir', 'Validade', 'Companhia', 'Qtde min. emissão', 'Valor de Compra', 'Status atual']}
                                rows={this.state.rows}
                                renderRow={this.renderRow}
                            />
                        </React.Fragment>
                    }
                </Card>
            </React.Fragment>
        )
    }
}

export default Supply