import React, { Component } from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import isEmpty from 'lodash/isEmpty'
import MaskedInput from 'react-maskedinput'
import { Redirect } from 'react-router'
import * as Yup from 'yup'

import Loading from '../template/Loading'

import { API } from '../../config/api'

const LoginSchema = Yup.object().shape({
    email: Yup.string()
        .required('Informe seu e-mail')
        .email('Informe um e-mail valido'),
    password: Yup.string()
        .required('Informe sua senha')
})

class LoginFormik extends Component {
    constructor(props) {
        super(props)

        this.state = {
            loginFailed: false,
            redirectTo: null
        }
    }

    renderForm = ({ values, touched, errors, dirty, isSubmitting }) => {
        if (isSubmitting === true)
        {
            return <Loading />
        }

        return (
            <Form>
                <div className="alert alert-danger" role="alert" style={{ display: this.state.loginFailed === true ? 'block' : 'none' }}>
                    As credenciais estão incorretas
                </div>
                <div className='form-group'>
                    <label>E-mail</label>
                    <Field name='email' className='form-control' />
                    <ErrorMessage name='email' component='div' className='invalid-tooltip' />
                </div>
                <div className='form-group'>
                    <label>Senha</label>
                    <Field name='password' type='password' className='form-control' />
                    <ErrorMessage name='password' component='div' className='invalid-tooltip' />
                </div>

                <button
                    type="submit"
                    className="btn btn--icon login__block__btn"
                    //disabled={isSubmitting || !isEmpty(errors) || !dirty}
                >
                    <i className="zmdi zmdi-long-arrow-right"></i>
                </button>
            </Form>
        )
    }

    handleSubmit = (values, actions) => {
        API.post(`login`, {
            ...values,
            app: true
        })
        .then((response) => {
            localStorage.setItem('jwtToken', response.data.data.token)
            this.props.loginDone()
            actions.setSubmitting(false)
        })
        .catch((error) => {
            this.setState({ loginFailed: true })
            actions.setSubmitting(false)
        })
    }

    render() {
        if (this.state.redirectTo !== null)
        {
            return <Redirect to={this.state.redirectTo} />
        }

        return (
            <Formik
                initialValues={{
                    email: '',
                    password: ''
                }}
                validationSchema={LoginSchema}
                onSubmit={this.handleSubmit}
                render={this.renderForm}
            />
        )
    }
}

export default LoginFormik