import React, { Component } from 'react'

import LoginFormik from './LoginFormik'

import './Login.css'

import loginBgVideo from '../../assets/login/loginbg.mp4'
import loginBgImage from '../../assets/login/loginbg.png'

class Login extends Component {
    componentWillMount = () => {
        document.title = 'Login - Sistema de Estoque'
    }

    render = () => {
        return (
            <div className="fullscreen-bg" style={{ backgroundImage: `url(${loginBgImage})` }}>
                <video loop muted autoPlay poster={loginBgImage} className="fullscreen-bg__video">
                    <source src={loginBgVideo} type="video/mp4" />
                </video>
                <div className="login">
                    <div className="login__block active" id="l-login">
                        <div className="login__block__header">
                            <i className="zmdi zmdi-account-circle"></i>
                            Informe seus dados
                        </div>

                        <div className="login__block__body">
                            <LoginFormik loginDone={this.props.loginDone} />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Login